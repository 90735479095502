import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sale_abi, token_abi, usdt_abi } from "../../config/abi";
import {   approveLimitSet,  buyConfirmed,   sun3, transactionConfirmPlease, transactionError, transactionQuery, transactionQueryError, transactionReady, transactionWaitsChain } from "../../app/web3Slice";
import { sale_address, token_address, usdt_address } from "../../config/config";

export const web3BuySlice = createSlice({
	initialState: {},
	name: 'web3/buy',
	reducers: {


	},
	extraReducers: (builder) => { // ------------------------------------------- EXTRA ---------------------------------
		builder.addCase(doBuyApprove.rejected, (state, action) => { console.error('doBuyApprove REJECTED!', action.error); });
		builder.addCase(doBuyBuy.rejected, (state, action) => { console.error('doBuyBuy REJECTED!', action.error); });
	}
});


export const {  } = web3BuySlice.actions;
export default web3BuySlice.reducer;




// ==================================================== APPROVE BUY ====================================================
export const doBuyApprove = createAsyncThunk('web3/buy/approve', async (amountStr, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		let amount = BigInt(amountStr * 1e18);

		const gasPrice = await web3.eth.getGasPrice();

		const contract = new web3.eth.Contract(usdt_abi, usdt_address);
		let tx = await contract.methods.approve(
			sale_address,
			amount
		)
		.send({
			from: sun3.selectedAccount, gasPrice
		})
		.once('transactionHash', function (hash) {
			transactionWaitsChain(thunkAPI, hash);
		})
		.once('confirmation', async function (confirmationNumber, receipt) {
			try {
				transactionQuery(thunkAPI);
				console.log('confirm', confirmationNumber, receipt);
				let USDTApproveLimit = await contract.methods.allowance(sun3.selectedAccount, sale_address).call();
				thunkAPI.dispatch(approveLimitSet(USDTApproveLimit / 1e18));
				transactionReady(thunkAPI);
			} catch (ex) {
				transactionQueryError(thunkAPI, ex);
			}
		})
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});


// ==================================================== BUY ====================================================
export const doBuyBuy = createAsyncThunk('web3/buy/buy', async (amountStr, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		let amount = BigInt(amountStr * 1e18);
		let refLink = localStorage.getItem('parentReferral') || '';

		const sale = new web3.eth.Contract(sale_abi, sale_address);
		const usdt = new web3.eth.Contract(usdt_abi, usdt_address);
		const token = new web3.eth.Contract(token_abi, token_address);

		const gasPrice = await web3.eth.getGasPrice();

		let tx = await sale.methods.buyWithUSDT(
			amount,
			refLink
		)
		.send({
			from: sun3.selectedAccount, gasPrice
		})
		.once('transactionHash', function (hash) {
			transactionWaitsChain(thunkAPI, hash);
		})
		.once('confirmation', async function (confirmationNumber, receipt) {
			try {
				//console.log('confirm', confirmationNumber, receipt);
				transactionQuery(thunkAPI);

				let USDTApproveLimit = await usdt.methods.allowance(sun3.selectedAccount, sale_address).call();
				USDTApproveLimit = USDTApproveLimit / 1e18;
				let uBalance = await usdt.methods.balanceOf(sun3.selectedAccount).call();
				uBalance = (uBalance / 1e18).toFixed(4);
				let tBalance = await token.methods.balanceOf(sun3.selectedAccount).call();
				tBalance = (tBalance / 1e18).toFixed(4);

				thunkAPI.dispatch(buyConfirmed({ USDTApproveLimit, uBalance, tBalance }));
				localStorage.removeItem('parentReferral');
				transactionReady(thunkAPI);
			} catch (ex) {
				transactionQueryError(thunkAPI, ex);
			}
		});
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});
