import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BlockAmount, dispNum } from "../../common";
import { doFetchPayouts, doWithdraw } from "./payoutSlice";
import { Modal } from "react-bootstrap";


export default function Withdraw() {
	const dispatch = useDispatch();
	const payouts = useSelector(state => state.web3Payout.payouts);
	const userSum = useSelector(state => state.web3Payout.userSum);
	const userWithdrawn = useSelector(state => state.web3Payout.userWithdrawn);
	const withdrawAble = useSelector(state => state.web3Payout.withdrawAble);
	const loaded = useSelector(state => state.web3Payout.loaded);
	const status = useSelector(state => state.web3.status);
	const [showConfirm, setShowConfirm] = useState(false);


	useEffect(() => {
		if (status == 'ready' && !loaded)
			dispatch(doFetchPayouts());
	}, [status, loaded]);

	const xWithdraw = () => {
		setShowConfirm(false);
		dispatch(doWithdraw());
	}

	return (<div className="page">
		<h1>Withdraw</h1>


		<Modal
			show={showConfirm}
			backdrop="static"
			keyboard={false}
			centered={true}
		>
			<Modal.Header>
				Confirm
			</Modal.Header>
			<Modal.Body >
				<div>Withdraw <span className='fw-bold amt'>{dispNum(withdrawAble)}</span>&nbsp;USDT?</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" onClick={() => setShowConfirm(false)} >Cancel</button>
				<button className="btn btn-primary" onClick={() => xWithdraw()} >Withdraw</button>
			</Modal.Footer>
		</Modal>

		<div className="page-main">



			<div className="block block-valuex">
				<span className="title">Sum payouts</span>
				<span className="value">
					<BlockAmount amount={userSum} />
					<span className="dim">USDT</span>
				</span>
			</div>

			<div className="block block-valuex">
				<span className="title">Already withdrawn</span>
				<span className="value">
					<BlockAmount amount={userWithdrawn} />
					<span className="dim">USDT</span>
				</span>
			</div>

			<div className="block block-value">
				<span className="title">Withdraw now</span>
				<button className="btn btn-primary"
					onClick={() => setShowConfirm(true)}
					disabled={withdrawAble < 10}
				>
					Withdraw&nbsp;
					<span className="amount">
						<BlockAmount amount={withdrawAble} />
					</span>
					&nbsp;<span className="dim">USDT</span>



				</button>
			</div>

			<div className="block">

			</div>


			<div className="block">
				{!payouts || payouts.length == 0 && <div>No payout available for withdrawal...</div>}
				{payouts && payouts.length > 0 && <div className="payout-table-container">
					<table>
						<tbody>
							{payouts.map(payout => <tr key={payout.id} >
								<td className="px-2">{payout.date.substr(0, 4) + "/" + payout.date.substr(-2)}</td>
								<td className="px-2" align="right"><span className="amt">{dispNum(payout.userAmount)}</span>&nbsp;USDT</td>
								<td className="px-2" >
									{payout.status
										? <button className="btn btn-primary btn-sm" onClick={() => dispatch(doWithdraw(payout))} >Withdraw</button>
										: <span className="text-muted">Withdrawn</span>}
								</td>
							</tr>)}
						</tbody>
					</table>
				</div>}
			</div>
			<hr />
			<div className="block">
				<button className="btn btn-outline-secondary" onClick={() => dispatch(doFetchPayouts())} >Refresh payouts</button>
			</div>
		</div>
		<div className='block'>
			<Link to="/" className='btn btn-secondary'>Home</Link>
		</div>
	</div>
	);
}