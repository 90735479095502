import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { sun3, transactionConfirmPlease, transactionError, transactionQuery, transactionQueryError, transactionReady, transactionWaitsChain, withdrawBalanceUpdated } from "../../app/web3Slice";
import { usdt_abi } from "../../config/abi";
import { payout_abi } from "../../config/abiPayout";
import { payout_address, usdt_address } from "../../config/config";

export const web3PayoutSlice = createSlice({
	name: 'web3/payout',
	initialState: {
		payouts: [],
		userSum : 0, 
		userWithdrawn : 0, 
		withdrawAble : 0,

		loaded: false,
		lastDate: null,
		lastDateOk: null,
		approved: 0,

	},
	reducers: {
		payoutsFetched: (state, action) => {
			state.payouts = action.payload.payouts;
			state.userSum = action.payload.userSum;
			state.userWithdrawn = action.payload.userWithdrawn;
			state.withdrawAble = action.payload.withdrawAble;
			state.loaded = true;
		},
		withdrawed: (state, action) => {
			const payout = action.payload;
			let p = state.payouts.find(p => p.id == payout.id);
			if (p)
				p.status = false;
		},
		lastDateSet: (state, action) => {
			state.lastDate = action.payload;
		},
		lastDateOkSet: (state, action) => {
			state.lastDateOk = action.payload;
		},
		approvedSet: (state, action) => {
			state.approved = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(doPayoutApprove.rejected, (state, action) => { console.error('doPayoutApprove REJECTED!', action.error); });
		builder.addCase(doPayout.rejected, (state, action) => { console.error('doPayout REJECTED!', action.error); });
		builder.addCase(doWithdraw.rejected, (state, action) => { console.error('doWithdraw REJECTED!', action.error); });
		builder.addCase(doFetchPayouts.rejected, (state, action) => { console.error('doFetchPayouts REJECTED!', action.error); });
		builder.addCase(doFetchLastDate.rejected, (state, action) => { console.error('doFetchLastDate REJECTED!', action.error); });
		builder.addCase(doSyncBalances.rejected, (state, action) => { console.error('doSyncBalances REJECTED!', action.error); });
	}
});


export const { payoutsFetched, withdrawed, lastDateSet, lastDateOkSet, approvedSet } = web3PayoutSlice.actions;
export default web3PayoutSlice.reducer;


export const doFetchLastDate = createAsyncThunk('web3/payout/doFetchLastDate', async (_, thunkAPI) => {
	try {
		console.log('----> fetch payout data');
		transactionQuery(thunkAPI, "Fetching your payout data");
		const web3 = new Web3(sun3.provider);

		const payout = new web3.eth.Contract(payout_abi, payout_address);
		const usdt = new web3.eth.Contract(usdt_abi, usdt_address);

		if (!sun3.selectedAccount) {
			const accounts = await web3.eth.getAccounts();
			sun3.selectedAccount = accounts[0];
		}

		let approved = await usdt.methods.allowance(sun3.selectedAccount, payout_address).call();
		thunkAPI.dispatch(approvedSet(approved / 1e18));

		let lastDate = await payout.methods.lastDate().call();
		thunkAPI.dispatch(lastDateSet(lastDate));

		const lastDateOk = await payout.methods.paidMonths(lastDate).call();
		thunkAPI.dispatch(lastDateOkSet(lastDateOk));

		transactionReady(thunkAPI);
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}

});

export const doWithdraw = createAsyncThunk('web3/payout/doWithdraw', async (_, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);
		const usdt = new web3.eth.Contract(usdt_abi, usdt_address);

		await payout.methods.withdraw()
			.send({ from: sun3.selectedAccount })
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					console.log('confirm', confirmationNumber, receipt);


					transactionReady(thunkAPI);
					thunkAPI.dispatch(doFetchPayouts());

				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
			;
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});

export const doFetchPayouts = createAsyncThunk('web3/payout/doFetchPayouts', async (_, thunkAPI) => {
	try {
		thunkAPI.dispatch(payoutsFetched([]));
		transactionQuery(thunkAPI, "Fetching payouts");
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);

		//let wDates = await payout.methods.userDatesList().call({ from: sun3.selectedAccount });		console.log(wDates);

		let wDates = await payout.methods.getDates().call();
		console.log('dates', wDates);

		let wAmounts = await payout.methods.getUserData(sun3.selectedAccount).call();
		console.log('amounts', wAmounts);
	
		let userSum = await payout.methods.userSum(sun3.selectedAccount).call();
		userSum = userSum / 1e18;
		console.log('userSum', userSum);
	
		let userWithdrawn = await payout.methods.userWithdrawn(sun3.selectedAccount).call();
		userWithdrawn = userWithdrawn / 1e18;
		console.log('userWithdrawn', userWithdrawn);
	
		let withdrawAble = userSum - userWithdrawn;
		console.log('withdrawAble', withdrawAble);


		let payouts = [];
		for (let id in wDates) {
			let date = wDates[id];
			let amount = wAmounts[id]  / 1e18;
			payouts.push({
				id, userAmount: amount, date
			});
		}
		console.log('payouts', payouts)
		thunkAPI.dispatch(payoutsFetched({payouts, userSum, userWithdrawn, withdrawAble}));
		transactionReady(thunkAPI, "Payouts fetched");
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});


export const doPayoutApprove = createAsyncThunk('web3/payout/doApprove', async ({ amount }, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);
		const web3 = new Web3(sun3.provider);
		const usdt = new web3.eth.Contract(usdt_abi, usdt_address);

		const gasPrice = await web3.eth.getGasPrice();

		amount = BigInt(amount * 1e18);



		let tx = await usdt.methods.approve(
			payout_address,
			amount,
		)
			.send({
				from: sun3.selectedAccount,
				gasPrice: gasPrice
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);

			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				transactionReady(thunkAPI, 'Payout USDT approval successful!');
				let approved = await usdt.methods.allowance(sun3.selectedAccount, payout_address).call();
				approved = approved / 1e18;
				thunkAPI.dispatch(approvedSet(approved));
			});
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});


export const doPayout = createAsyncThunk('web3/payout/doPayout', async ({ amount, date }, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);
		const xAmount = BigInt(amount) * BigInt(1e18);
		console.log('date:', date);
		console.log('amount:', xAmount);
		let tx = await payout.methods.addDateAndAmount(date, xAmount)
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);

			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				transactionReady(thunkAPI, 'Payout successful!');
				let lastDate = await payout.methods.lastDate().call();
				thunkAPI.dispatch(lastDateSet(lastDate));
				const lastDateOk = await payout.methods.paidMonths(lastDate).call();
				thunkAPI.dispatch(lastDateOkSet(lastDateOk));				
			})
			;
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});

export const doSyncBalances = createAsyncThunk('web3/payout/doSyncBalances', async (_, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);

		let tx = await payout.methods.syncBalances()
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);

			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				transactionReady(thunkAPI, 'Payout successful!');
				let lastDate = await payout.methods.lastDate().call();
				thunkAPI.dispatch(lastDateSet(lastDate));
				const lastDateOk = await payout.methods.paidMonths(lastDate).call();
				thunkAPI.dispatch(lastDateOkSet(lastDateOk));				
			})
			;
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});