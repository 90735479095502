import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BlockAmount, CSVToArray, dispAddress, dispNum } from "../../common";
import { doFetchLastDate, doPayout, doPayoutApprove, doSyncBalances } from "./payoutSlice";
import { CHAIN, PAYOUT_IGNORE_ACCOUNTS, token_address } from "../../config/config";
import { Link } from "react-router-dom";
import { selectAccountData } from "../../app/web3Slice";
import { doBuyApprove } from "../buy/buySlice";


/*
teng kódja:  https://teng.hu/S2p/payout.html
*/




export default function Payout2() {

	const dispatch = useDispatch();

	const lastDate = useSelector(state => state.web3Payout.lastDate);
	const lastDateOk = useSelector(state => state.web3Payout.lastDateOk);
	const approved = useSelector(state => state.web3Payout.approved);
	const [nextYear, setNextYear] = useState(2024);
	const [nextMonth, setNextMonth] = useState(1);
	const [payoutAmount, setPayoutAmount] = useState(0);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showConfirm2, setShowConfirm2] = useState(false);

	const accountData = useSelector(selectAccountData);

	useEffect(() => {
		if (!lastDate)
			dispatch(doFetchLastDate());
	}, []);

	// set next date
	useEffect(() => {
		if (!lastDate || !parseInt(lastDate)) {
			let currentYear = new Date().getFullYear();
			let currentMonth = new Date().getMonth() + 1;
			currentMonth--;
			if (currentMonth <= 0) {
				currentMonth = currentMonth + 12;
				currentYear--;
			}
			setNextYear(currentYear)
			setNextMonth(currentMonth)
			return;
		}
		let year = Math.floor(parseInt(lastDate) / 100);
		let month = parseInt(lastDate) % 100;
		console.log('year', year, month);
		month++;
		if (month > 12) {
			month = month - 12;
			year++;
		}
		console.log('year2', year, month);
		setNextYear(year)
		setNextMonth(month)

	}, [lastDate]);




	const payout = () => {
		setShowConfirm(false);
		dispatch(doPayout({ amount: payoutAmount, date: nextYear * 100 + nextMonth }));
	}

	const xSynchBalances = () => {
		setShowConfirm2(false);
		dispatch(doSyncBalances());
	}

	return (
		<div className="page">


			<Modal
				show={showConfirm}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Header>
					Confirm
				</Modal.Header>
				<Modal.Body >
					<div>Payout <span className='fw-bold amt'>{dispNum(payoutAmount)}</span>&nbsp;USDT?</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary" onClick={() => setShowConfirm(false)} >Cancel</button>
					<button className="btn btn-primary" onClick={() => payout()} >Payout</button>
				</Modal.Footer>
			</Modal>


			<Modal
				show={showConfirm2}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Header>
					Confirm
				</Modal.Header>
				<Modal.Body >
					<div>Synchronize balances for {lastDate}?</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary" onClick={() => setShowConfirm2(false)} >Cancel</button>
					<button className="btn btn-primary" onClick={() => xSynchBalances()} >Synchronize</button>
				</Modal.Footer>
			</Modal>


			<h1>Payout</h1>

			<div className="page-main">

				<div className="block block-value">
					<span className="title">USDT Balance</span>
					<span className="value">
						<BlockAmount amount={accountData.uBalance} />
						<span className="dim">USDT</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">USDT Approve limit</span>
					<span className="value">
						<BlockAmount amount={approved} />
						<span className="dim">USDT</span>
					</span>
				</div>


				<div className="block block-value">
					<span className="title">Last date</span>
					<span className="value">
						{lastDate ? <span className="amount">{lastDate.substring(0,4)} / {lastDate.substring(4,6)}</span> : '-'} 

					</span>
				</div>



				<div className="block">
					<div className="btn-group"> 
						<select disabled value={nextYear} onChange={e => setNextYear(parseInt(e.currentTarget.value))} >
							{[...Array(3).keys()].map(i => <option key={i} value={2023 + i} >{2023 + i}</option>)}
						</select>
						<select disabled value={nextMonth} onChange={e => setNextMonth(parseInt(e.currentTarget.value))}>
							{[...Array(12).keys()].map(i => i + 1).map(i => <option key={i} value={i}>{('' + (100 + i)).substring(1, 3)}</option>)}
						</select>
						<input type="number" className="form-control amt" style={{ textAlign: 'right', width: '200px' }}
							value={payoutAmount} onChange={(e) => setPayoutAmount(parseFloat(e.target.value))} />
						<button type="button"
							className={'btn' + (payoutAmount <= approved ? ' btn-secondary' : ' btn-primary')}
							onClick={() => dispatch(doPayoutApprove({ amount: payoutAmount }))} >Approve</button>
						<button type="button" className='btn btn-primary'
							disabled={payoutAmount > approved || payoutAmount > accountData.uBalance
								|| (parseInt(lastDate) >= 100 * nextYear + nextMonth)}
							onClick={payout}>Payout</button>
					</div>
					{payoutAmount > approved && (<div className='calc text-danger'>
						Approved amount is not enough
					</div>)}
					{payoutAmount > accountData.uBalance && (<div className='calc text-danger'>
						Your USDT balace is not enough
					</div>)}
					{payoutAmount <= 0 && (<div className='calc'>
						Enter the amount!
					</div>)}
					{(parseInt(lastDate) >= 100 * nextYear + nextMonth) && (<div className='calc text-danger'>
						Date must be later then last date!
					</div>)}
				</div>

				<div className="block block-value">
					{!lastDateOk && (<><div className='calc text-danger'>
						Last date is not synchronized!
					</div>
						<button type="button" className='btn btn-secondary' onClick={()=> setShowConfirm2(true)}>Synch balances</button>
					</>)}

				</div>				


				{/*balances?.length > 0 && (
					<div className="block">
						<div className="payout-table-container">
							<table className="list">
								<thead>
									<tr>
										<th>Helder address</th>
										<th align="right" >Balance</th>
										<th align="right" >Payout</th>
									</tr>
								</thead>
								<tbody>
									{balances.map(balance => (<tr key={balance.address} className={balance.ignored ? 'ignored' : ''} >
										<td align="left">{dispAddress(balance.address)}</td>
										<td align="right" className="amt">{dispNum(balance.balance, 0)}</td>
										<td align="right" className="amt">{dispNum(balance.payout)}</td>
									</tr>))}
								</tbody>
							</table>
						</div>

						<div className="block block-value">
							<span className="title">Total payout</span>
							<span className="value">
								<BlockAmount amount={sumPayout} />
								<span className="dim">USDT</span>
							</span>
						</div>

						<div className="block block-value">
							<span className="title">Payout date</span>
							<span className="value">
								{nextDate
									? nextDate.substr(0, 4) + "/" + nextDate.substr(-2)
									: '?'}
							</span>
						</div>

						<div className="block block-value">
							<button className="btn btn-primary" onClick={() => setShowConfirm(true)} >Payout</button>
						</div>

								</div>)*/}

				<div className='block'>
					<Link to="/" className='btn btn-secondary'>Home</Link>
				</div>
			</div >
		</div>
	)
}









