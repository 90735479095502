import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect, selectAccountData } from '../../app/web3Slice';
import { BlockAmount, BlockDim, dispAddress } from '../../common';
import { Link } from 'react-router-dom';




export default function Wallet() {

	// ------- selectors -----------
	const walletAddress = useSelector((state) => state.web3.walletAddress);
	const accountData = useSelector(selectAccountData);


	const dispatch = useDispatch();

	const [buyAmountCalc, setBuyAmountCalc] = useState(null);
	const onBuyAmountKeyUp = (e) => {
		console.log(e.target.value);
		setBuyAmountCalc(parseInt(e.target.value) / 0.01);
	}

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}


	return (
		<div className="page">
			<h1>Wallet</h1>
			<div className="page-main">
				<div className="block block-value">
					<span className="title">Wallet address</span>
					<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
				</div>

				<div className="block block-value">
					<span className="title">Token balance</span>
					<span className="value">
						<BlockAmount amount={accountData.tBalance} />
						<BlockDim dim="SDBN2" />
					</span>
				</div>

				<div className="block block-value">
					<span className="title">USDT balance</span>
					<span className="value">
						<BlockAmount amount={accountData.uBalance} />
						<span className="dim">USDT</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">BNB Balance</span>
					<span className="value">
						<BlockAmount amount={accountData.bBalance} />
						<span className="dim">BNB</span>
					</span>
				</div>

			</div>
			<div className='block'>
				<Link to="/" className='btn btn-secondary'>Home</Link>
			</div>

		</div>
	)
};


