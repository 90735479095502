import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect, addToken, doLogin, doLogout } from './web3Slice';
import { Link } from 'react-router-dom';
import { SmLink } from '../components/elem';




export default function Home() {

	// ------- selectors -----------
	const userData = useSelector(state => state.web3.userData);


	const dispatch = useDispatch();

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}

	return (
		<div className="page">
			<h1>SDBN2 TOKEN</h1>
			<div className="page-main">
				<br />
				<SmLink to="/wallet" icon="wallet">Wallet</SmLink>
				<SmLink to="/buy" icon="currency-exchange">Buy</SmLink>
				<SmLink to="/referral" icon="currency-exchange">Referral program</SmLink>
				<SmLink to="/withdraw" icon="wallet">Withdraw</SmLink>

				<hr />

				<div className="other-buttons">
					{!userData?.userId
						?
						<button className='btn btn-primary' onClick={() => dispatch(doLogin())} >Login</button>
						: (userData?.email || userData?.phone
							? <Link className='btn btn-outline-secondary' to={`/wallet-recovery`}   >Wallet recovery</Link>
							: <Link className='btn btn-danger' to={`/wallet-recovery`}   >Wallet recovery!</Link>
						)}

					{!!userData?.userId && <button className='btn btn-secondary' onClick={() => dispatch(doLogout())}  >Logout</button>}

				</div>

				<hr />
				<div className="other-buttons">
					<button className='btn btn-outline-secondary' onClick={() => dispatch(addToken())}  >Add SDBN2 to your wallet++</button>
					<button className='btn btn-outline-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>
				</div>



				{!!userData?.isAdmin && <div>
					<hr />
					<h4>Owner</h4>
					<hr />
					<div className="other-buttons">
						<Link className='btn btn-outline-secondary' to={`/owner-statistics`}>Owner statistics</Link>
						<Link className='btn btn-outline-secondary' to={`/admin/payout`}>Payout</Link>
						<Link className='btn btn-outline-secondary' to={`/admin/sdbn2plus`}>SDBN2+</Link>
					</div>
				</div>}

			</div>
		</div>
	)
};


