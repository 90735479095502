import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessages from '../toastMessages';
import { connect, disconnect, doLogin, errorClose } from './web3Slice';

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { dispAddress } from '../common';
import Buy from '../features/buy/Buy';
import OwnerStatistics from '../features/owner/ownerStatistics';
import Payout2 from '../features/payout/Payout2';
import Withdraw from '../features/payout/Withdraw';
import WalletRecovery from '../features/recovery/WalletRecovery';
import Referral from '../features/referral/Referral';
import Sdbn2Plus from '../features/sdbn2Plus/Sdbn2Plus';
import Wallet from '../features/wallet/Wallet';
import Home from './Home';




export default function App() {

	// ------- selectors -----------
	const status = useSelector((state) => state.web3.status);
	const statusText = useSelector((state) => state.web3.statusText);
	const userData = useSelector(state => state.web3.userData);
	const walletAddress = useSelector((state) => state.web3.walletAddress);


	const statusModalRef = useRef(null);

	const dispatch = useDispatch();

	const showSpinner = (['query', 'networksetup'].indexOf(status) > -1);

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}

	const [design, setDesign] = useState(true);
	/*const [design, setDesign] = useState(!!localStorage.getItem('design'));
	const designIt = (b) => {
		localStorage.setItem('design', b);
		setDesign(b);
	}*/



	return (

		<div className="page-container container" >
			<Modal
				show={status == 'error'}
				centered={true}
				onHide={() => dispatch(errorClose())}
			>
				<Modal.Header closeButton>
					<Modal.Title>Error</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: statusText }}></div>
				</Modal.Body>
				<Modal.Footer>
					{status == 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(connect())} >Try again</Button>}
					{status != 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(errorClose())} >Ok</Button>}
				</Modal.Footer>
			</Modal>

			<Modal
				show={showSpinner}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Body className='spinner-body' >
					<br /><br />
					<img src="/images/spinner_orange.png" />
					<br /><br />
					<div dangerouslySetInnerHTML={{ __html: status === 'connecting' ? 'connecting...' : statusText }}></div>
					<br /><br />
					<a className='btn btn-outline-secondary btn-sm' onClick={() => window.location.reload()}>Reload page</a>
				</Modal.Body>
			</Modal>

			{status == 'disconnected' && <div className="page" style={{ justifyContent: 'center' }}>
				<div className='page-main'>
					<br /><br /><br /><br />
					<img className='img-fluid' src="/images/logotext.png" style={{ width: '400px' }} />
					<br /><br /><br /><br />
					{!window.ethereum
						&& <a className='btn btn-primary' href="https://metamask.io/" target="_blank">Install metamask</a>
					}
					<br /><br /><br />
					<button className='btn btn-primary' onClick={() => dispatch(connect())}  > Connect wallet</button>
					<br /><br /><br />
				</div>
			</div>}

			{false && status != 'disconnected' && !userData?.userId && <div className="page" style={{ justifyContent: 'center' }}>
				<div className='page-main'>
					<br /><br /><br /><br />
					<img src="/images/logotext.png" style={{ width: '400px' }} />
					<br /><br />
					<div className="block block-value">
						<span className="title">Wallet address</span>
						<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
					</div>
					<br /><br /><br /><br />
					{<button className='btn btn-primary' onClick={() => dispatch(doLogin())}  > Login</button>}
					<br /><br /><br /><br />
					{<button className='btn btn-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>}
					<br />
				</div>
			</div>}

			{['query', 'ready', 'error'].indexOf(status) >= 0 && (true || userData?.userId) &&
				<Router  >
					{/*
						<div className="nav-container">
						<nav>
							<ul>
								<li>
									<Link to={`/wallet`}  >Wallet</Link>
								</li>
								<li>
									<Link to={`/buy`}>Buy</Link>
								</li>
								<li>
									<Link to={`/referral`}>Referral</Link>
								</li>
								<li>
									<Link to={`/withdraw`}>Withdraw</Link>
								</li>
							</ul>
						</nav>
						</div>
					*/}
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/wallet" element={<Wallet />} />
						<Route path="/buy" element={<Buy />} />
						<Route path="/referral" element={<Referral />} />
						<Route path="/owner-statistics" element={<OwnerStatistics />} />
						<Route path="/wallet-recovery" element={<WalletRecovery />} />
						<Route path="/withdraw" element={<Withdraw />} />
						<Route path="/admin/payout" element={<Payout2 />} />
						<Route path="/admin/sdbn2plus" element={<Sdbn2Plus />} />
					</Routes>
				</Router>}

			<ToastMessages />
			<div className='popup'> {status}&nbsp;<button onClick={() => designIt(!design)}>design</button></div>
		</div>
	)
};


