import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { sale_abi, token_abi, usdt_abi } from "../config/abi";
import { CHAIN, sale_address, token_address, usdt_address, API_BASE } from '../config/config';
import { token_abi_sdbn2plus, token_address_sdbn2plus } from '../config/abiSDBN2plus';


// -------------------- shoud've encapsulate ----------------------------
export const sun3 = {
	Web3Modal: window.Web3Modal.default,
	web3Modal: null, // set in init(),
	WalletConnectProvider: window.WalletConnectProvider.default,
	provider: null, // set in init(), connect()
	selectedAccount: null
}


// -------------------- /shoud've encapsulate ----------------------------

const STATUSES = ['disconnected', 'connecting', 'networksetup', 'query', 'ready', 'error', 'connecterror'];

export const web3Slice = createSlice({
	name: 'web3',
	initialState: {
		status: 'disconnected',
		statusText: null,
		walletAddress: null,

		messages: [],

		userData: {
			userId: null,
			isAdmin: false,
			email: null,
			phone: null
		},

		accountData: {
			bBalance: null, // BNB - tranzakciós költség
			uBalance: null, // USDT - virt dollárom
			tBalance: null, // Sun - Részvény
			lvl1Bonus: null,
			lvl2Bonus: null,
			lvl3Bonus: null,

			walletRecoveryId: null,

			USDTApproveLimit: null
		},
		ownerStatistics: {
			allSold: null,
			lvl1SumBonus: null,
			lvl2SumBonus: null,
			lvl3SumBonus: null,
			allSumBonus: null
		},
		profileWatch: {
			buyerAmount: null,
			lvl1Bonus: null,
			lvl2Bonus: null,
			lvl3Bonus: null,
		},

		referral: {
			myReferralName: null, 		// comes from account fetch or addReferral
			parentReferralName: null, 	// comes from localStorage at startup
			parentReferralSavedAddress: null,   // comes from chain-contract
			parentReferralSavedName: null,   // comes from chain-contract
			checkName: {
				pending: false,
				name: null,
				available: null
			}
		},

		sdbn2plus: {
			monthStatus: [],
			arsav: [],
			lastTxHashes: [],
			tokenBalance: 0,
			sellBalance: 0,
			allowedAmount: 0,
			summary: {
				maxTransactionDate : null,
				maxTransferDate : null,
				sumPayValue : 0,
				sumPayValuePending : 0,
				sumPayValueReady : 0,
				sumTokenValue : 0,
				sumTokenValueNotSelected : 0,
				sumTokenValueSelected : 0
			}
		}

	},
	reducers: {
		addMessage: (state, action) => {
			let msg = action.payload;
			if (typeof msg == 'string') {
				msg = {
					message: msg
				};
			}
			MSG_ID++
			msg.id = MSG_ID;
			msg.ts = new Date().getTime();
			state.messages.push(msg);
		},
		addMessageError: (state, action) => {
			let errorText = action.payload;
			if (errorText.message)
				errorText = errorText.message;
			MSG_ID++
			let msg = { message: errorText, variant: 'danger' }
			msg.id = MSG_ID;
			msg.ts = new Date().getTime();
			state.messages.push(msg);
			state.status = 'ready';
		},
		messageCleanup: (state) => {
			let newMsgs = [];
			let ts = new Date().getTime();
			let changed = false;
			for (let msg of state.messages) {
				if (ts - msg.ts < 5000) {
					newMsgs.push(msg);
				} else {
					changed = true;
				}

			}
			if (changed)
				state.messages = newMsgs;
		},
		setStatus: (state, action) => {
			console.log('STATUS', action.payload);
			let status = action.payload;
			let statusText = null;
			if (typeof status == 'object') {
				statusText = status.text;
				status = status.status;
			}
			if (STATUSES.indexOf(status) == -1)
				console.error('INVALID STATUS', action.payload);
			if (status == 'networksetup' && !statusText)
				statusText = 'Confirm the network please!';
			state.status = status;
			state.statusText = statusText
		},
		setError: (state, action) => {
			let errorText = action.payload;
			if (errorText.message)
				errorText = errorText.message;
			state.status = 'error';
			state.statusText = errorText
		},
		setConnectError: (state, action) => {
			let errorText = action.payload;
			if (errorText.message)
				errorText = errorText.message;
			state.status = 'connecterror';
			state.statusText = errorText
		},
		errorClose: (state, action) => {
			if (state == 'connecterror') {
				state.status = 'disconnected';
				state.statusText = null;
			} else {
				state.status = 'ready';
				state.statusText = null;
			}
		},


		walletIdSet: (state, action) => {
			state.accountData.refId = action.payload;
		},
		walletAddressSet: (state, action) => {
			state.walletAddress = action.payload;
		},
		userDataSet: (state, action) => {
			let ud = action.payload || {};
			state.userData = {
				userId: ud.userId,
				isAdmin: ud.isAdmin,
				email: ud.email,
				phone: ud.phone
			}
		},
		fetchAccountDataSet: (state, action) => {
			if (action.payload === true) {
				console.log('-- fetch return pending --');
				return;
			}
			state.accountData = action.payload.accountData;
			state.ownerStatistics = action.payload.ownerStatistics;
			state.referral.myReferralName = action.payload.myReferralName;
			state.referral.parentReferralSavedAddress = action.payload.parentReferralSavedAddress;
			state.referral.parentReferralSavedName = action.payload.parentReferralSavedName;
			state.status = 'ready';
			state.statusText = null;
		},

		// --- buy ----
		approveLimitSet: (state, action) => {
			state.accountData.USDTApproveLimit = action.payload;
		},
		buyConfirmed: (state, action) => {
			state.accountData.USDTApproveLimit = action.payload.USDTApproveLimit;
			state.accountData.uBalance = action.payload.uBalance;
			state.accountData.tBalance = action.payload.tBalance;
		},
		withdrawBalanceUpdated: (state, action) => {
			state.accountData.uBalance = action.payload.uBalance;
			state.accountData.cBalance = action.payload.cBalance;
		},


		profileWatchReaded: (state, action) => {
			state.profileWatch = action.payload;
		},

		referralNameChecked: (state, action) => {
			state.referral.checkName = action.payload;
		},
		myReferralNameSet: (state, action) => {
			state.referral.myReferralName = action.payload;
		},
		parentReferralNameSet: (state, action) => { // localStore
			state.referral.parentReferralName = action.payload;
		},

		// --------- sdbn2plus --------------
		sdbn2plusMonthStatusSet: (state, action) => {
			state.sdbn2plus.monthStatus = action.payload.monthStatus;
			state.sdbn2plus.arsav = action.payload.arsav;
			state.sdbn2plus.summary = action.payload.summary;
			state.sdbn2plus.lastTxHashes = action.payload.lastTxHashes;
		},
		sdbn2plusBalancesSet: (state, action) => {
			state.sdbn2plus.tokenBalance = action.payload.tokenBalance;
			state.sdbn2plus.sellBalance = action.payload.sellBalance;
			state.sdbn2plus.allowedAmount = action.payload.allowedAmount;
		},

	},
	extraReducers: (builder) => { // ------------------------------------------- EXTRA ---------------------------------

		builder.addCase(web3init.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(fetchAccountData.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(disconnect.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(connect.rejected, (state, action) => { _addMessageError(state, action); });

		builder.addCase(doLogin.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doCheckLogin.rejected, (state, action) => { _addMessageError(state, action); });

		builder.addCase(addToken.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doRecoverySave.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(addReferral.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(referralCheckAvailable.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(profileWatchRead.rejected, (state, action) => { _addMessageError(state, action); });

		builder.addCase(doUploadTransactionsSave.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doUploadTransfersSave.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doSdbn2PlusStatusLoad.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doSdbn2PlusSend.rejected, (state, action) => { _addMessageError(state, action); });
		builder.addCase(doSdbn2PlusAllow.rejected, (state, action) => { _addMessageError(state, action); });
	}
})

// =============================  Action creators are generated for each case reducer function =======================
export const {
	addMessage, addMessageError, messageCleanup
	, setStatus, setError, setConnectError, errorClose

	, fetchAccountDataSet
	, walletAddressSet, userDataSet
	, walletIdSet

	, approveLimitSet, buyConfirmed
	, withdrawBalanceUpdated

	, profileWatchReaded
	, referralNameChecked, myReferralNameSet, parentReferralNameSet

	, sdbn2plusMonthStatusSet, sdbn2plusBalancesSet
} = web3Slice.actions
export default web3Slice.reducer;



// --------------- sector --------------------
export const selectAccountData = state => state.web3.accountData;
export const selectOwnerStatistics = state => state.web3.ownerStatistics;


export function prepareEx(ex) {
	console.error(ex);

	return ex?.response?.data?.publicMessage || ex?.message || JSON.stringify(ex);
}

// ========================================== MESSAGE ==========================================
// CALL ONLY FORM EXTRA REDUCER REJECT!
let MSG_ID = 0;
export const _addMessageError = (state, action) => {
	web3Slice.caseReducers.addMessage(state.web3 || state, { type: 'web3/addMessage', payload: { message: action.error.message, variant: 'danger' } });
}
export const _setStatus = (state, status, text) => {
	web3Slice.caseReducers.setStatus(state, { type: 'web3/setStatus', payload: { status, text } });
}


// ========================================== FOR THUNKs ==========================================
export function transactionError(thunkAPI, ex) {
	//xxx thunkAPI.dispatch(addMessageError(prepareEx(ex)));
	thunkAPI.dispatch(setError(prepareEx(ex)));

}

export function transactionInitError(thunkAPI, ex) {
	thunkAPI.dispatch(setError(prepareEx(ex)));
}

export function transactionQueryError(thunkAPI, ex) {
	thunkAPI.dispatch(setError(prepareEx(ex)));

	//xxx thunkAPI.dispatch(addMessageError(prepareEx(ex)));
}

export function transactionConfirmPlease(thunkAPI, msg) {
	thunkAPI.dispatch(setStatus({ status: 'query', text: msg || 'Confirm the transaction please' }));
}



export function transactionWaitsChain(thunkAPI, hash) {
	let href = CHAIN.blockExplorerUrls + `tx/${hash}`;
	let text = `Waiting network to confirm <a target='_blank' href='${href}'>transaction</a>`;
	thunkAPI.dispatch(setStatus({ status: 'query', text }));
}


export function transactionQuery(thunkAPI, msg) {
	thunkAPI.dispatch(setStatus({ status: 'query', text: msg || 'Query new value' }));
}

export function transactionReady(thunkAPI, msg) {
	if (msg)
		thunkAPI.dispatch(addMessage(msg));
	thunkAPI.dispatch(setStatus('ready'));
}







// ================================================== INIT =======================================================
export const web3init = createAsyncThunk('web3/init', async (_, thunkAPI) => {
	if (window.location.protocol !== 'https:') {
		console.error("Not HTTPS!"); //return;TODO!!!!
	}

	const providerOptions = {
		walletconnect: {
			package: sun3.WalletConnectProvider,
			options: {
				rpc: {
					[parseInt(CHAIN.chainId)]: CHAIN.rpcUrls[0]
				}
				///rpc: {97: "https://data-seed-prebsc-1-s1.binance.org:8545/",},
			}
		},
		binancechainwallet: {
			package: true
		}
	};

	sun3.web3Modal = new sun3.Web3Modal({
		cacheProvider: false, // optional
		providerOptions, // required
		disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
	});

	if (localStorage.getItem('isWalletConnected') !== 'true')
		return;

	try {
		if (window.ethereum) {
			sun3.provider = window.ethereum;
			console.log('PROVIDER.FROM.INIT (window.ethereum)', sun3.provider);
			const web3 = new Web3(sun3.provider);
			const accounts = await web3.eth.getAccounts();
			if (!accounts || !Array.isArray(accounts) || accounts.length === 0)
				sun3.provider = null;

		}

		if (!window.ethereum || !sun3.provider) {
			sun3.provider = await sun3.web3Modal.connect();
			console.log('PROVIDER.FROM.INIT (web3Modal.connect())', sun3.provider);
		}
		listenChanges(thunkAPI, sun3.provider);
		thunkAPI.dispatch(fetchAccountData())
	} catch (ex) {
		thunkAPI.dispatch(disconnect());
		transactionInitError(thunkAPI, ex || 'Metamask popup aleready open!');
	}
});


// ================================================== LISTEN CHANES =======================================================
// removed, because mobile kept disconnecting it.
function listenChanges(thunkAPI, provider) {
	console.log('--- listen changes ----');
	/* xxx
	// Subscribe to accounts change
	provider.on("accountsChanged", (accounts) => {
		thunkAPI.dispatch(addMessageError("Account changed"));		
		thunkAPI.dispatch(disconnect());
	});

	// Subscribe to chainId change
	provider.on("chainChanged", (chainId) => {
		thunkAPI.dispatch(addMessageError("Chain changed"));		
		thunkAPI.dispatch(disconnect());
	});

	// Subscribe to networkId change
	provider.on("networkChanged", (networkId) => {
		thunkAPI.dispatch(addMessageError("Network changed"));		
		thunkAPI.dispatch(disconnect());
	});
	*/
}


// ================================================== CHECK LOGIN =======================================================
export const doCheckLogin = createAsyncThunk('web3/startup', async (_, thunkAPI) => {
	try {
		let state = thunkAPI.getState();
		let alreadyUserData = state.web3.userData;
		if (alreadyUserData && alreadyUserData.userId) // már beloginoltunk csak a fetchAccount a végén mindig meghív
			return;

		let sess_WalletAddress = window.localStorage.getItem('walletAddress');
		let sess_nonce = window.localStorage.getItem('nonce');
		if (!sess_WalletAddress || !sess_nonce)
			return;


		const web3 = new Web3(sun3.provider);
		const accounts = await web3.eth.getAccounts();
		const walletAddress = sun3.selectedAccount = accounts[0];
		if (!walletAddress) {
			return;
		}
		thunkAPI.dispatch(walletAddressSet(walletAddress));
		let body = { walletAddress };
		if (sess_WalletAddress == walletAddress) {
			body.nonce = sess_nonce;
		} else {
			window.localStorage.removeItem('walletAddress');
			window.localStorage.removeItem('nonce');
			return;
		}
		transactionQuery(thunkAPI, "Checking server...");


		const resp = await axios.post(API_BASE + '/startup', body, { withCredentials: true });
		if (resp.data.userId) { // ---- already logged in ----
			thunkAPI.dispatch(userDataSet(resp.data));
			thunkAPI.dispatch(fetchAccountData());
		}
		transactionReady(thunkAPI);
	} catch (ex) {
		transactionInitError(thunkAPI, ex);
	}
});
// ================================================== LOGIN =======================================================
export const doLogin = createAsyncThunk('web3/login', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Checking server...");
		const web3 = new Web3(sun3.provider);

		const accounts = await web3.eth.getAccounts();
		const walletAddress = sun3.selectedAccount = accounts[0];
		if (!walletAddress) {
			thunkAPI.dispatch(connect());
			return;
		}
		thunkAPI.dispatch(walletAddressSet(walletAddress));

		let sess_WalletAddress = window.localStorage.getItem('walletAddress');
		let sess_nonce = window.localStorage.getItem('nonce');
		let body = { walletAddress };
		if (sess_WalletAddress == walletAddress) {
			body.nonce = sess_nonce;
		} else {
			window.localStorage.removeItem('walletAddress');
			window.localStorage.removeItem('nonce');
		}
		const resp = await axios.post(API_BASE + '/startup', body, { withCredentials: true });
		if (resp.data.userId) { // ---- already logged in ----
			thunkAPI.dispatch(userDataSet(resp.data));
			thunkAPI.dispatch(fetchAccountData());
			return;
		}

		const nonce = resp.data.nonce;
		if (!nonce || !nonce.length || nonce.length < 10)
			throw "Something wrong with nonce.";
		transactionQuery(thunkAPI, "Sign your login code please!");
		let nonceSigned = await web3.eth.personal.sign(web3.utils.fromUtf8(nonce), walletAddress);
		//console.log('nonceSigned', nonceSigned);
		transactionQuery(thunkAPI, "Logging in...");
		const loginResp = await axios.post(API_BASE + '/login', {
			walletAddress, nonce, nonceSigned
		}, { withCredentials: true });
		//console.log('resp', loginResp.data);

		window.localStorage.setItem('walletAddress', walletAddress);
		window.localStorage.setItem('nonce', nonce);

		thunkAPI.dispatch(userDataSet(loginResp.data));
		transactionReady(thunkAPI);
		//thunkAPI.dispatch(fetchAccountData());

	} catch (ex) {
		transactionInitError(thunkAPI, ex);
	}
});

// ================================================== LOGOUT =======================================================
export const doLogout = createAsyncThunk('web3/logout', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Logging out...");
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');
		if (walletAddress && nonce) {
			await axios.post(API_BASE + '/logout', { walletAddress, nonce }, { withCredentials: true });
			window.localStorage.removeItem('walletAddress');
			window.localStorage.removeItem('nonce');
		}
		thunkAPI.dispatch(userDataSet());
		transactionReady(thunkAPI);

	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

// ==================================================== CONNECT ====================================================
export const connect = createAsyncThunk('web3/connect', async (_, thunkAPI) => {
	thunkAPI.dispatch(setStatus('connecting'));
	//if (localStorage.getItem('isWalletConnected') !== 'true') {
	console.log('0) before connect', sun3.provider);

	sun3.provider = await sun3.web3Modal.connect();

	console.log('PROVIDER.FROM.CONNECT', sun3.provider);

	localStorage.setItem('isWalletConnected', true);
	const web3 = new Web3(sun3.provider);

	console.log('2) web3', web3);
	sun3.provider.enable();
	console.log('3) enabled');

	listenChanges(thunkAPI, sun3.provider);


	// Get connected chain id from Ethereum node
	const chainId = await web3.eth.getChainId();
	console.log('4) chainID', chainId);

	// Load chain information over an HTTP API
	const chainData = evmChains.getChain(chainId);
	console.log('5) chain', chainId, chainData, chainData.name);


	if (chainId != CHAIN.chainId) {
		console.log("CALL networkChange");
		thunkAPI.dispatch(changeNetwork());
	} else {
		// xxx await thunkAPI.dispatch(doLogin());
		console.log("CALL fetchAccountData");
		await thunkAPI.dispatch(fetchAccountData());
	}
});

// ==================================================== DISCONNECT ====================================================
export const disconnect = createAsyncThunk('web3/disconnect', async (_, thunkAPI) => {
	thunkAPI.dispatch(setStatus('disconnected'));
	localStorage.setItem('isWalletConnected', false);
	console.log("Killing the wallet connection", sun3.provider);

	// TODO: Which providers have close method?
	if (sun3.provider?.close) {
		await sun3.provider.close();
		console.log('privider close returned');
		// If the cached provider is not cleared,
		// WalletConnect will default to the existing session
		// and does not allow to re-scan the QR code with a new wallet.
		// Depending on your use case you may want or want not his behavir.
		await sun3.web3Modal.clearCachedProvider();
		sun3.provider = null;
		console.log('clear cache returned');
	}
	sun3.selectedAccount = null;
});


// ==================================================== CHANGE  NETWORK ====================================================
const changeNetwork = createAsyncThunk('web3/changeNetwork', async (_, thunkAPI) => {
	thunkAPI.dispatch(setStatus('networksetup'));
	console.log("BEFORE CHANGENETWORK");
	await ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [{ chainId: CHAIN.chainId }]
	})
		.then(async (a, b, c) => {
			await thunkAPI.dispatch(fetchAccountData());
		})
		.catch((err) => {
			console.error('change catch', err.code);
			if (err?.code == 4902) {
				//addFuseNetwork(chainId);
				thunkAPI.dispatch(addNetwork());
			} else if (err?.code == -32002) {
				thunkAPI.dispatch(setStatus('networksetup'))
			} else {
				//setError({ status: null, code: err.code }); //??? TODO
				thunkAPI.dispatch(setConnectError(err));
			}
		});
});


// ==================================================== ADD NETWORK ====================================================
export const addNetwork = createAsyncThunk('web3/addNetwork', async () => {
	ethereum.request({
		method: 'wallet_addEthereumChain',
		params: [CHAIN]
	})
		.once(async () => {
			//xxx await thunkAPI.dispatch(doLogin());
			await thunkAPI.dispatch(fetchAccountData());
		})
		.catch((error) => {
			console.log(error) //TODO
		});
});













// ==================================================== FETCH ACCOUNT DATA ====================================================
export const fetchAccountData = createAsyncThunk('web3/fetchAccountData', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Fetching account data...");
		const web3 = new Web3(sun3.provider);

		const sale = new web3.eth.Contract(sale_abi, sale_address);
		const usdt = new web3.eth.Contract(usdt_abi, usdt_address);
		const token = new web3.eth.Contract(token_abi, token_address);

		const chainId = await web3.eth.getChainId();
		//const chainData = evmChains.getChain(chainId);

		if (chainId != CHAIN.chainId) {
			thunkAPI.dispatch(changeNetwork());
			return true; // pending
		}

		// Get list of accounts of the connected wallet
		const accounts = await web3.eth.getAccounts();

		// MetaMask does not give you all accounts, only the selected account
		sun3.selectedAccount = accounts[0];



		let bBalance = await web3.eth.getBalance(sun3.selectedAccount);
		bBalance = (bBalance / 1e18).toFixed(4);

		let uBalance = await usdt.methods.balanceOf(sun3.selectedAccount).call();
		uBalance = (uBalance / 1e18).toFixed(4);

		let tBalance = await token.methods.balanceOf(sun3.selectedAccount).call();
		tBalance = (tBalance / 1e18).toFixed(4);

		let lvl1Bonus = await sale.methods.referralLvl1Bonus(sun3.selectedAccount).call();
		lvl1Bonus = (lvl1Bonus / 1e18).toFixed(4);

		let lvl2Bonus = await sale.methods.referralLvl2Bonus(sun3.selectedAccount).call();
		lvl2Bonus = (lvl2Bonus / 1e18).toFixed(4);

		let lvl3Bonus = await sale.methods.referralLvl3Bonus(sun3.selectedAccount).call();
		lvl3Bonus = (lvl3Bonus / 1e18).toFixed(4);

		let walletRecoveryId = await sale.methods.walletConfirm(sun3.selectedAccount).call();

		// ------- referrals --------
		let myReferralName = await sale.methods.referralAddressName(sun3.selectedAccount).call();
		let parentReferralSavedAddress = await sale.methods.referralTree(sun3.selectedAccount).call();
		let parentReferralSavedName = await sale.methods.referralAddressName(parentReferralSavedAddress).call();



		// original: usdtAllowance
		let USDTApproveLimit = await usdt.methods.allowance(sun3.selectedAccount, sale_address).call();
		USDTApproveLimit = USDTApproveLimit / 1e18;

		let allSold = await sale.methods.soldAmount().call();
		allSold = (allSold / 1e18).toFixed(4);

		let lvl1SumBonus = await sale.methods.bonusLvl1().call();
		let lvl2SumBonus = await sale.methods.bonusLvl2().call();
		let lvl3SumBonus = await sale.methods.bonusLvl3().call();
		let allSumBonus = (lvl1SumBonus / 1e18 + lvl2SumBonus / 1e18 + lvl3SumBonus / 1e18).toFixed(4);
		lvl1SumBonus = (lvl1SumBonus / 1e18).toFixed(4);
		lvl2SumBonus = (lvl2SumBonus / 1e18).toFixed(4);
		lvl3SumBonus = (lvl3SumBonus / 1e18).toFixed(4);

		thunkAPI.dispatch(walletAddressSet(sun3.selectedAccount));
		let result = {
			accountData: {
				bBalance,
				uBalance,
				tBalance,
				lvl1Bonus,
				lvl2Bonus,
				lvl3Bonus,
				walletRecoveryId,
				USDTApproveLimit,
			},
			ownerStatistics: {
				allSold,
				lvl1SumBonus, lvl2SumBonus, lvl3SumBonus, allSumBonus
			},
			myReferralName,
			parentReferralSavedAddress,
			parentReferralSavedName
		};
		thunkAPI.dispatch(fetchAccountDataSet(result));
		transactionReady(thunkAPI);
		thunkAPI.dispatch(doCheckLogin());
	}
	catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}

}); // fetchAccountData









// ==================================================== ADD TOKEN ====================================================
export const addToken = createAsyncThunk('web3/addToken', async () => {
	const wasAdded = await ethereum.request({
		method: 'wallet_watchAsset',
		params: {
			type: 'ERC20', // Initially only supports ERC20, but eventually more!
			options: {
				address: token_address, // The address that the token is at.
				symbol: 'SDBN2', // A ticker symbol or shorthand, up to 5 chars.
				decimals: 18 //, The number of decimals in the token
				//image: tokenImage, // A string url of the token logo
			},
		},
	});

	if (wasAdded) {
		console.log('Thanks for your interest!');
	} else {
		console.log('Your loss!');
	}
});







// ==================================================== AVAILABLE CHECK ====================================================
export const referralCheckAvailable = createAsyncThunk('web3/referralCheckAvailable', async (name, thunkAPI) => {
	console.log('pending');
	thunkAPI.dispatch(referralNameChecked({ pending: true, name: name }));

	const web3 = new Web3(sun3.provider);
	const sale = new web3.eth.Contract(sale_abi, sale_address);

	let address = await sale.methods.referralNameAddress(name).call();

	if (address == '0x0000000000000000000000000000000000000000') {
		//document.querySelector("#message").innerHTML = "Available";
		thunkAPI.dispatch(referralNameChecked({ pending: false, name: name, available: true }));
	} else {
		//document.querySelector("#message").innerHTML = "NOT Available!";
		thunkAPI.dispatch(referralNameChecked({ pending: false, name: name, available: false }));
	}

});

// ==================================================== ADD REFERRAL ====================================================
export const addReferral = createAsyncThunk('web3/addReferral', async (_, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);
		const name = thunkAPI.getState().web3.referral.checkName.name;
		console.log('addreferral', name);

		const sale = new web3.eth.Contract(sale_abi, sale_address);
		let tx = await sale.methods.setNamedReferralEnable(
			name
		)
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionQuery(thunkAPI);
					const myReferralName = await sale.methods.referralAddressName(sun3.selectedAccount).call();
					thunkAPI.dispatch(myReferralNameSet(myReferralName));
					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}

			});
	} catch (ex) {
		transactionError(thunkAPI, ex);

	}
});



export const doRecoverySave = createAsyncThunk('web3/recoverySave', async ({ email, phone }, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		transactionQuery(thunkAPI, "Saving recovery info...");
		const resp = await axios.post(API_BASE + '/recovery', { walletAddress, nonce, email, phone }, { withCredentials: true });
		thunkAPI.dispatch(userDataSet(resp.data));
		transactionReady(thunkAPI, "Saved");
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});












// ==================================================== PROFILE WATCH READ ====================================================
export const profileWatchRead = createAsyncThunk('web3/profileWatchRead', async (address, thunkAPI) => {
	const web3 = new Web3(sun3.provider);
	//let address = document.querySelector("#profile").value;

	const sale = new web3.eth.Contract(sale_abi, sale_address);

	let buyerAmount = await sale.methods.buyerAmount(address).call();
	buyerAmount = (buyerAmount / 1e18).toFixed(4)

	let lvl1Bonus = await sale.methods.referralLvl1Bonus(address).call();
	lvl1Bonus = (lvl1Bonus / 1e18).toFixed(4);

	let lvl2Bonus = await sale.methods.referralLvl2Bonus(address).call();
	lvl2Bonus = (lvl2Bonus / 1e18).toFixed(4);

	let lvl3Bonus = await sale.methods.referralLvl3Bonus(address).call();
	lvl3Bonus = (lvl3Bonus / 1e18).toFixed(4);

	thunkAPI.dispatch(profileWatchReaded({
		buyerAmount, lvl1Bonus, lvl2Bonus, lvl3Bonus
	}));

});


// ==================================================== SDBN2 PLUS ====================================================
export const doUploadTransactionsSave = createAsyncThunk('web3/uploadTransactionsSave', async ({ csv }, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		transactionQuery(thunkAPI, "Uploading transactions...");
		const resp = await axios.post(API_BASE + '/sdbn2plus/upload_transactions', { walletAddress, nonce, csv }, { withCredentials: true });
		transactionReady(thunkAPI, resp.data.message);
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
	thunkAPI.dispatch(doSdbn2PlusStatusLoad());
});

export const doUploadTransfersSave = createAsyncThunk('web3/uploadTransfersSave', async ({ csv }, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		transactionQuery(thunkAPI, "Uploading Transfers...");
		const resp = await axios.post(API_BASE + '/sdbn2plus/upload_transfers', { walletAddress, nonce, csv }, { withCredentials: true });
		transactionReady(thunkAPI, resp.data.message);
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
	thunkAPI.dispatch(doSdbn2PlusStatusLoad());
});

export const doSdbn2PlusStatusLoad = createAsyncThunk('web3/sdbn2plus/monthStatusLoad', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Load status...");

		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');
		const web3 = new Web3(sun3.provider);

		const token = new web3.eth.Contract(token_abi_sdbn2plus, token_address_sdbn2plus);
		const sell_token = new web3.eth.Contract(token_abi, token_address);

		//let bBalance = await web3.eth.getBalance(sun3.selectedAccount);
		//bBalance = (bBalance/1e18).toFixed(4);

		let tokenBalance = await token.methods.balanceOf(sun3.selectedAccount).call();
		tokenBalance = (tokenBalance / 1e18).toFixed(4);

		let sellBalance = await sell_token.methods.balanceOf(sale_address).call();
		sellBalance = (sellBalance / 1e18).toFixed(0);

		let allowedAmount = await token.methods.allowance(sun3.selectedAccount, token_address_sdbn2plus).call();
		allowedAmount = (allowedAmount / 1e18).toFixed(0);

		console.log(tokenBalance, sellBalance, allowedAmount);
		thunkAPI.dispatch(sdbn2plusBalancesSet({ tokenBalance, sellBalance, allowedAmount }));

		// --------------- status from server ---------------
		const resp = await axios.get(API_BASE + `/sdbn2plus/month_status?walletAddress=${walletAddress}&nonce=${nonce}`, { withCredentials: true });
		thunkAPI.dispatch(sdbn2plusMonthStatusSet(resp.data));

		transactionReady(thunkAPI, resp.data.message);
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

export const doSdbn2PlusAllow = createAsyncThunk('web3/sdbn2plus/allow', async (amount, thunkAPI) => {
	try {
		const web3 = new Web3(sun3.provider);
		const token = new web3.eth.Contract(token_abi_sdbn2plus, token_address_sdbn2plus);
		//amount = BigInt(400000000000000000000000000);
		const xAmount = BigInt(amount) * BigInt(1e18);

		await token.methods.approve(token_address_sdbn2plus, xAmount)
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					transactionReady(thunkAPI);
					thunkAPI.dispatch(doSdbn2PlusStatusLoad());
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			});
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}

});


export const doSdbn2PlusSend = createAsyncThunk('web3/sdbn2plus/send', async (_, thunkAPI) => {
	try {
		const walletAddress = window.localStorage.getItem('walletAddress');
		const nonce = window.localStorage.getItem('nonce');

		// ---------- load next 2000 ----------		
		transactionQuery(thunkAPI, "Load list...");
		const resp = await axios.get(API_BASE + `/sdbn2plus/send_list?walletAddress=${walletAddress}&nonce=${nonce}`, { withCredentials: true });
		const addressis = [];
		const amounts = [];
		for (let row of resp.data.items) {
			addressis.push(row.To);
			amounts.push(BigInt(row.PayValue) * BigInt(1e18));
			//console.log('---->', typeof row.PayValue, row.PayValue, BigInt(row.PayValue) * BigInt(1e18));
		}
		const most = resp.data.most;
		console.log(most);
		console.log(addressis);		
		console.log(amounts);


		// ---------- send next 2000 ----------		
		const web3 = new Web3(sun3.provider);
		const token = new web3.eth.Contract(token_abi_sdbn2plus, token_address_sdbn2plus);
		let txhash = '???';
		try {

			let tx = await token.methods.multiTransfer(
				token_address_sdbn2plus,
				addressis,
				amounts
			)
				.send({
					from: sun3.selectedAccount
				})
				.once('transactionHash', function (hash) {
					txhash = hash;
					transactionWaitsChain(thunkAPI, hash);
				})
				.once('confirmation', async function (confirmationNumber, receipt) {
					try {
						transactionQuery(thunkAPI);
						await axios.post(API_BASE + `/sdbn2plus/send_list?walletAddress=${walletAddress}&nonce=${nonce}`, {most, txhash }, { withCredentials: true });
						thunkAPI.dispatch(doSdbn2PlusStatusLoad());
						//transactionReady(thunkAPI);
					} catch (ex) {
						transactionQueryError(thunkAPI, ex);
					}


				})
				;
		} catch (ex) {
			transactionQueryError(thunkAPI, ex);
		}
	} catch (ex) {
		transactionQueryError(thunkAPI, ex);
	}
});

