
import { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BlockAmount } from "../../common";
import { addMessage, addReferral, myReferralNameSet, referralCheckAvailable, selectAccountData } from "../../app/web3Slice";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";

export default function Referral() {
	// ------- refs -----------
	//const referralNameInputRef = useRef(null);
	const accountData = useSelector(selectAccountData);
	const checkName = useSelector(state => state.web3.referral.checkName);
	const myReferralName = useSelector(state => state.web3.referral.myReferralName);
	const parentReferralSavedAddress = useSelector(state => state.web3.referral.parentReferralSavedAddress);
	const parentReferralSavedName = useSelector(state => state.web3.referral.parentReferralSavedName);
	const parentReferralName = useSelector(state => state.web3.referral.parentReferralName);
	const [copied, setCopied] = useState(false);
	const [name, setName] = useState(checkName.name || "");
	const [showConfirm, setShowConfirm] = useState(false);
	const [referralLink, setReferralLink] = useState((new URL(window.location).origin) + '?parentReferral=' + myReferralName)

	const dispatch = useDispatch();

	useEffect(() => {
		console.log('useEffect', name, 'x', checkName.name);
		/*if (name === null)
			setName(checkName.name);*/
		if (name && name != checkName.name) {
			const getData = setTimeout(() => {
				dispatch(referralCheckAvailable(name))
			}, 600);
			return () => clearTimeout(getData);
		}
	}, [name]);

	useEffect(() => {
		setReferralLink((new URL(window.location).origin) + '?parentReferral=' + myReferralName);
	}, [myReferralName]);

	const setReferralName = () => {
		setShowConfirm(false);
		dispatch(addReferral());
	}

	let nameState = (<div className="text-danger">Type your referral name, please!</div>);
	if (checkName.name && name) {
		if (checkName.pending) nameState = (<div>Cheching if name is available...</div>);
		else if (checkName.available) nameState = (<div>Name '{checkName.name}' is available</div>);
		else nameState = (<div className="text-danger fw-bold">Name '{checkName.name}' is not available</div>);
	}

	const doCopied = () => {
		setCopied(true);
		dispatch(addMessage("Link copied to clipboard"));
	}

	return (
		<div className="page">


			<Modal
				show={showConfirm}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Header>
					Confirm
				</Modal.Header>

				<Modal.Body >
					<div>Set your referral name to <span className='fw-bold'>{checkName.name}</span>?</div>
					<div>You cannot change it later!</div>
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary" onClick={() => setShowConfirm(false)} >Cancel</button>
					<button className="btn btn-primary" onClick={() => setReferralName()} >Set name</button>
				</Modal.Footer>
			</Modal>


			<h1>Referral program</h1>

			<div className="page-main">

				{myReferralName &&
					<div className="block block-value">
						<div className="title">My referral name</div>
						<div className="value">{myReferralName}
							{false && <a onClick={() => dispatch(myReferralNameSet(null))} className="btn btn-danger" >x</a>}
						</div>
					</div>
				}
				{myReferralName &&
					<div className="block block-value">
						<div className="title">Referral link</div>

						<CopyToClipboard className="btn-group" text={referralLink}
							onCopy={() => doCopied()}>
							<div>
								<input type="text" className="form-control referral-link" readOnly={true} value={referralLink} />
								<button className="btn btn-primary">Copy</button>
							</div>
						</CopyToClipboard>
					</div>
				}

				{!myReferralName &&
					<div className="block">
						<div className="title">Referral link generate</div>
						<div className="text">(Only once, later cannot be modified)</div>
						<div className="btn-group">
							<input type="text" className="form-control"
								onChange={(event) => setName(event.target.value)}
								value={name}
							/>
							<button className="btn btn-primary"
								style={{ width: '14em', height: '4em', verticalAlign: 'baseline' }}
								onClick={() => setShowConfirm(true)}
								disabled={!name || name != checkName.name || checkName.pending || !checkName.available}
							>
								{checkName.pending
									? <Spinner animation="border" role="status" >
										<span className="visually-hidden">Loading...</span>
									</Spinner>
									: "Set referral name"
								}
							</button>
						</div>
						<div className="sub-info">
							{nameState}
						</div>
					</div>
				}

				<div className="block block-value">
					<span className="title">LVL 1 Bonus</span>
					<span className="value">
						<BlockAmount amount={accountData.lvl1Bonus} />
						<span className="dim">SDBN2</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">LVL 2 Bonus</span>
					<span className="value">
						<BlockAmount amount={accountData.lvl2Bonus} />
						<span className="dim">SDBN2</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">LVL 3 Bonus</span>
					<span className="value">
						<BlockAmount amount={accountData.lvl3Bonus} />
						<span className="dim">SDBN2</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="text-muted">Parent referral name: {parentReferralSavedName || parentReferralName || '[n/a]'}</span>
				</div>


			</div>
			<div className='block'>
				<Link to="/" className='btn btn-secondary'>Home</Link>
			</div>

		</div>
	)
}