import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profileWatchRead, selectAccountData, selectOwnerStatistics } from "../../app/web3Slice";

export default function OwnerStatistics() {
	// ------- refs -----------
	const accountData = useSelector(selectAccountData);
	const ownerStatistics = useSelector(selectOwnerStatistics);
	const profileWatch = useSelector((state) => state.web3.profileWatch);
	const profileWatcherAddressRef = useRef(null);

	const dispatch = useDispatch();


	return (
		<div className="page">
			<h1>Owner statistics</h1>
			<div className="page-main">

				All sold amount <p id="allSold">{ownerStatistics.allSold}</p>
				Lvl 1 bonus: <p id="lvl1SumBonus">{ownerStatistics.lvl1SumBonus}</p>
				Lvl 2 bonus: <p id="lvl2SumBonus">{ownerStatistics.lvl2SumBonus}</p>
				Lvl 3 bonus: <p id="lvl3SumBonus">{ownerStatistics.lvl3SumBonus}</p>
				All bonus: <p id="allSumBonus">{ownerStatistics.allSumBonus}</p>

				<hr />

				<h3>Profile watcher:</h3>
				Address: <input type="text" id="profile" ref={profileWatcherAddressRef} /><br />
				Bought amount: <p id="pBuy">{profileWatch.buyerAmount}</p>
				LVL1 bonus: <p id="pLVL1Bonus">{profileWatch.lvl1Bonus}</p>
				LVL2 bonus: <p id="pLVL2Bonus">{profileWatch.lvl2Bonus}</p>
				LVL3 bonus: <p id="pLVL3Bonus">{profileWatch.lvl3Bonus}</p>
				<button type="button" id="read" className="btn btn-primary" onClick={() => dispatch(profileWatchRead(profileWatcherAddressRef.current.value))}   >Read</button>
				<div className='block'>
					<Link to="/" className='btn btn-secondary'>Home</Link>
				</div>				
			</div>
		</div>
	)
}