
//export const API_BASE = "http://localhost:25002/api";
//export const API_BASE = "http://176.58.118.145:25001/api";
//export const API_BASE = "https://sunbuy.coimbraits.hu:25001/api";
export const API_BASE = "https://token.sdbn2.com:25001/api";



// ------------------------------- TESTNET ----------------------------------
/*
export const sale_address = "0x28b48A5291cbCfF449A2faD841550c9e311486C8";
export const usdt_address = "0x3749C4a6D6fe2A72D7381184DDFdF563e2189f23";
export const token_address = "0xF619B07da7892F2B3Cb005ECbCCbE087AB6cEaAc";
export const payout_address = "0xfBFF4De57FA69B488023E3eC1a7C416c7b6f3322";
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];
export const CHAIN = {
	chainId: '0x61',
	chainName: 'Binance Smart Chain Testnet',
	nativeCurrency: {
		name: 'tBNB',
		symbol: 'tBNB',
		decimals: 18
	},
	rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com/']
};
*/



// ------------------------------- PRODUCTION ----------------------------------

export const sale_address = "0x3eb4237E402d5954d6cE106C045c1aB473BCC39f";
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";
export const token_address = "0xEED7Cc0ea13987509d727577e634f6722333bbDF";
//export const payout_address = "0xeff0E33439A3dE65F2ec803078154777f84Ae072";
//export const payout_address = "0x4F1E1F71ef929FA19Ac7C8DB71eC4eA7437725Da";
//export const payout_address = "0xF2F9a135B67c31dC5C493c1CE0c33F37bA511Bd9";
export const payout_address = "0x62F915d3bCE0119862d3d68C1649341b930c1719";
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];

export const CHAIN = {
	chainId: '0x38',
	chainName: 'Binance Smart Chain',
	nativeCurrency: {
		name: 'Binance Coin',
		symbol: 'BNB',
		decimals: 18
	},
	rpcUrls: ['https://bsc-dataseed.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com/']
}

