import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BlockAmount, BlockDim, CSVToArray, dispAddress, dispNum } from "../../common";
//import { doFetchLastDate, doPayout } from "./payoutSlice";
import { API_BASE, CHAIN, PAYOUT_IGNORE_ACCOUNTS, token_address } from "../../config/config";
import { Link } from "react-router-dom";
import { doSdbn2PlusAllow, doSdbn2PlusStatusLoad, doSdbn2PlusSend, doUploadTransactionsSave, doUploadTransfersSave, sun3 } from "../../app/web3Slice";







export default function Plus() {

	const dispatch = useDispatch();

	const summary = useSelector((state) => state.web3.sdbn2plus.summary);
	const monthStatus = useSelector((state) => state.web3.sdbn2plus.monthStatus);
	const arsav = useSelector((state) => state.web3.sdbn2plus.arsav);
	const lastTxHashes = useSelector((state) => state.web3.sdbn2plus.lastTxHashes);
	const tokenBalance = useSelector((state) => state.web3.sdbn2plus.tokenBalance);
	const sellBalance = useSelector((state) => state.web3.sdbn2plus.sellBalance);
	const allowedAmount = useSelector((state) => state.web3.sdbn2plus.allowedAmount);

	const [allowed, setAllowed] = useState(0);

	const [sumPayout, setSumPayout] = useState(0);
	const [showConfirm, setShowConfirm] = useState(false);

	useEffect(() => {
		if (sun3.selectedAccount)
			dispatch(doSdbn2PlusStatusLoad());
	}, [sun3.selectedAccount]);

	const handleCsvTransactions = (e) => {
		if (!e.target?.files || e.target.files.length <= 0)
			return;
		var fr = new FileReader();
		fr.onload = function () {
			console.log(typeof fr.result);
			dispatch(doUploadTransactionsSave({ csv: fr.result }));
		}
		fr.readAsText(e.target.files[0]);
	}

	const handleCsvTransfers = (e) => {
		if (!e.target?.files || e.target.files.length <= 0)
			return;
		var fr = new FileReader();
		fr.onload = function () {
			console.log(typeof fr.result);
			dispatch(doUploadTransfersSave({ csv: fr.result }));
		}
		fr.readAsText(e.target.files[0]);
	}

	const payout = () => {
		setShowConfirm(false);
		dispatch(doSdbn2PlusSend());
	}



	return (
		<div className="page">


			<Modal
				show={showConfirm}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Header>
					Confirm
				</Modal.Header>

				<Modal.Footer>
					<button className="btn btn-secondary" onClick={() => setShowConfirm(false)} >Cancel</button>
					<button className="btn btn-primary" onClick={() => payout()} >Payout</button>
				</Modal.Footer>
			</Modal>





			<h1>SDBN2+</h1>

			<div className="page-main">

				{/* ---------------------------------------------- binance values --------------------------------- */}
				<div className="block block-value">
					<span className="title">SDBN2 sell contract balance </span>
					<span className="value">
						<BlockAmount amount={sellBalance} frac={2} />
						<BlockDim dim="SDBN2" />
					</span>
				</div>

				{/* ---------------------------------------------- db summray --------------------------------- */}
				<div className="block block-value">
					<span className="title">Sum TokenValue for payout</span>
					<span className="value">
						<BlockAmount amount={summary.sumTokenValueSelected} frac={2} />
						<BlockDim dim="SDBN2" />
					</span>
				</div>

				<div className="block block-value">
					<span className="title">Sum TokenValue NO payout</span>
					<span className="value">
						<BlockAmount amount={summary.sumTokenValueNotSelected} frac={2} />
						<BlockDim dim="SDBN2" />
					</span>
				</div>

				<div className="block block-value">
					<span className="title">SDBN2 sell contract balance <br /> + TokenValue for payout + NO payout</span>
					<span className="value">
						<BlockAmount amount={Math.round(parseFloat(sellBalance) + parseFloat(summary.sumTokenValueSelected) + parseFloat(summary.sumTokenValueNotSelected))} frac={2} />
						<BlockDim dim="SDBN2" />
					</span>
					{400000000 != Math.round(parseFloat(sellBalance) + parseFloat(summary.sumTokenValueSelected) + parseFloat(summary.sumTokenValueNotSelected))
						&& (<div className='calc text-danger'>
							Upload most recent CSVs please!
						</div>)}
				</div>

				<hr />



				{/* ---------------------------------------------- CSVs --------------------------------- */}
				<h2>Upload CSVs</h2>
				<div className="block">
					<span>
						<b>1.</b>&nbsp;Download <strong>transactions</strong> csv from &nbsp;
						<a href={`https://bscscan.com/exportData?type=address&a=0x3eb4237E402d5954d6cE106C045c1aB473BCC39f`}
							target="_blank" >here</a>.
					</span>
					<span>
						Latest: {summary.maxTransactionDate?.substring(0, 10)}
					</span>
				</div>
				<div className="block">
					<div>
						<b>2.</b>&nbsp;<input type="file" onChange={handleCsvTransactions} />
					</div>
				</div>

				<div className="block">
					<span>
						<b>3.</b>&nbsp;Download <strong>transfers</strong> csv from &nbsp;
						<a href={`https://bscscan.com/exportData?type=addresstokentxns&a=0x3eb4237E402d5954d6cE106C045c1aB473BCC39f`}
							target="_blank" >here</a>.
					</span>
					<span>
						Latest: {summary.maxTransferDate?.substring(0, 10)}
					</span>
				</div>

				<div className="block">
					<div>
						<b>4.</b>&nbsp;<input type="file" onChange={handleCsvTransfers} />
					</div>
				</div>



				{/* ---------------------------------------------- Monthly report --------------------------------- */}
				{monthStatus?.length > 0 && (
					<div className="block">
						<div className="payout-table-container">
							<table className="list">
								<thead>
									<tr>
										<th>Month</th>
										<th align="right" >Transfers</th>
										<th align="right" >Transactions</th>
										<th align="right" >Payout</th>
										<th align="right" >Payout ready</th>
									</tr>
								</thead>
								<tbody>
									{monthStatus.map(s => (<tr key={'' + s.year + '/' + s.month} className={s.ignored ? 'ignored' : ''} >
										<td align="left">{'' + s.year + '/' + ('' + (100 + s.month)).substring(1)}</td>
										<td align="right" className="amt">{s.transfer_cnt}</td>
										<td align="right" className="amt">{s.valid_transaction_cnt}</td>
										<td align="right" className="amt">{s.pay_cnt}</td>
										<td align="right" className="amt">{s.pay_ready_cnt}</td>
									</tr>))}
								</tbody>
							</table>
						</div>



						<hr />



						{/* ---------------------------------------------- Payout --------------------------------- */}
						<h2>Payout</h2>

						<div className="block block-value">
							<span className="title">Pending payout</span>
							<span className="value">
								<BlockAmount amount={summary.sumPayValuePending} />
								<BlockDim dim="SDBN2+" />
							</span>
						</div>

						<div className="block block-value">
							<span className="title">Payout ready</span>
							<span className="value">
								<BlockAmount amount={summary.sumPayValueReady} />
								<BlockDim dim="SDBN2+" />
							</span>
						</div>

						<div className="block block-value">
							<span className="title">Token balance</span>
							<span className="value">
								<BlockAmount amount={tokenBalance} />
								<BlockDim dim="SDBN2+" />
							</span>
						</div>

						<div className="block block-value">
							<span className="title">Allowed amount</span>
							<span className="value">
								<BlockAmount amount={allowedAmount} />
								<BlockDim dim="SDBN2+" />
							</span>
						</div>

						<div className="block">
							<div className="btn-group">
								<input type="number" className="form-control amt" style={{ textAlign: 'right' }}
									value={allowed} onChange={(e) => setAllowed(parseFloat(e.target.value))} />
								<button type="button"
									className='btn btn-primary'
									onClick={() => dispatch(doSdbn2PlusAllow(allowed))} >Allow</button>
							</div>
						</div>


						<div className="block block-value">
							<button className="btn btn-primary" onClick={() => setShowConfirm(true)} >Payout</button>
						</div>

					</div>)}

				{/* ---------------------------------------------- Recent transactions --------------------------------- */}
				<hr />
				{lastTxHashes?.length > 0 && (
					<div className="block">
						<h4>Recent payout transactions</h4>
						<div className="payout-table-container">
							<table className="list">
								<thead>
									<tr>
										<th align="left" >Time</th>
										<th align="left" >TxHash</th>
									</tr>
								</thead>
								<tbody>
									{lastTxHashes.map((s, ix) => (<tr key={ix} className={s.ignored ? 'ignored' : ''}>
										<td align="left" className="">{s.PayoutReadyAt?.substring(0, 16).replace("T", " ")}</td>
										<td align="left" className="">
											<a href={`https://bscscan.com/tx/${s.PayoutTxHash}`} target="_blank"
												style={{ "display": "block", "maxWidth": "200px", "overflow": "hidden", "textOverflow": "ellipsis" }}
											>
												{s.PayoutTxHash}
											</a>
										</td>
									</tr>))}
								</tbody>
							</table>
						</div>

					</div>)}



				{/* ---------------------------------------------- Ársáv report --------------------------------- */}
				<hr />
				<h2>Sold by volume</h2>
				{arsav?.length > 0 && (
					<div className="block">
						<div className="payout-table-container">
							<table className="list">
								<thead>
									<tr>
										<th align="right" >From</th>
										<th align="right" >To</th>
										<th align="right" >Amount</th>
										<th align="right" >Count</th>
									</tr>
								</thead>
								<tbody>
									{arsav.map((s, ix) => (<tr key={ix} className={s.ignored ? 'ignored' : ''}>
										<td align="right" className="amt">{dispNum(s.v1, 0)}</td>
										<td align="right" className="amt">{dispNum(s.v2, 0)}</td>
										<td align="right" className="amt">{dispNum(s.amount, 0)}</td>
										<td align="right" className="amt">{dispNum(s.cnt, 0)}</td>
									</tr>))}
								</tbody>
							</table>
						</div>

					</div>)}









				<div className='block'>
					<Link to="/" className='btn btn-secondary'>Home</Link>
				</div>
			</div >
		</div>
	)
}









